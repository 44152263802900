//@require "./**/*.html"
/* eslint-disable
    camelcase,
    handle-callback-err,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'

import { downgradeComponent } from '@angular/upgrade/static';
import { FilterPill } from '../_ng/components/filter-pill/filter-pill.component';
import { ChangeAlertIcon } from '../_ng/components/change-alert-icon/change-alert-icon.component';
import { NoteManager } from '../_ng/components/note-manager/note-manager.component';
import { AngularEditor } from './utilities/angular-editor';
import * as angular from 'angular';
import * as _ from 'lodash';
// var modernizr = require("modernizr");
declare var Modernizr: any;

// import uiRouter from 'angular-ui-router';
 
var FilterType = {
    Filter: 1,
    Select: 2,
    Multi: 3,
    Date: 4,
    NodeTreeBuilder: 5,
    Radio: 6,
    CustomSearch: 7,
    MultiAPISearch: 8
};

export var complyosClient = angular.module('complyosClient', [
  'angularjs-dropdown-multiselect',
  // 'angulartics.google.analytics',
  // 'angulartics',
  'btford.markdown',
  'config',
  'FortyAUServices',
  'fortyDate',
  'ngCookies',
  'ngFileSaver',
  'ngFileUpload',
  'ngResource',
  'ngRoute',
  'ngSanitize',
  'restangular',
  'ui.bootstrap',
  'ui.router',
  'hc.marked',
  'ngAnimate',
  'chart.js',
  'isteven-multi-select'
])
  // .run(['$templateCache', function ($templateCache) {
  //   var url = 'views/common/navigation.html';
  //   $templateCache.put(url, require(url));
  // }]);
  .constant('Modernizr', Modernizr)

  // complyosClient.constant('constants', {
  .constant('constants', {
    FilterType: FilterType,
    schedule_generation_state: {
      schedule_on: 'schedule_on',
      no_schedule: 'no_schedule',
      start_date_needed: 'start_date_needed',
      managed_by_paired_application: 'managed_by_paired_application',
      archived: 'archived'
    },

    FilterBy: {
      constructor () {
        this.Field = ''
        this.Op = ''
        this.Value = ''
        this.displayName = ''
        this.displayValue = ''
      }
    },

    FieldFilterConfig: {
      constructor () {
        this.fieldDisplayName = ''
        this.fieldValue = ''
        this.type = FilterType.Filter
        this.list = []
      }
    }
  })

//   .directive('sampleComponent',
//     downgradeComponent({ component: SampleComponent }) as angular.IDirectiveFactory)
//   .directive('ngFilterPill',
//     downgradeComponent({ component: FilterPill }) as angular.IDirectiveFactory)
  // .directive('ngChangeAlertIcon',
  //   downgradeComponent({ component: ChangeAlertIcon }) as angular.IDirectiveFactory)
  // .directive('ngNoteManager',
  //   downgradeComponent({ component: NoteManager }) as angular.IDirectiveFactory)

  .directive('angulareditor',
    downgradeComponent({ component: AngularEditor, inputs: ['postId'], outputs: ['postId'] }) as angular.IDirectiveFactory)

  .config(['$stateProvider', '$urlRouterProvider', /*'$analyticsProvider',*/ '$httpProvider', '$locationProvider', '$provide', 'ENV', 'RestangularProvider', 'sessionLauncherProvider', function (
    $stateProvider: any, 
    $urlRouterProvider: any,
    // $analyticsProvider: any,
    $httpProvider: any,
    $locationProvider: any,
    $provide: any,
    ENV: any,
    RestangularProvider: any,
    sessionLauncherProvider: any
  ) {
    // $analyticsProvider.firstPageview(true)
    // $analyticsProvider.withAutoBase(true)

    // Set some defaults to be used on every request.
    $httpProvider.defaults.headers = {
      useXDomain: true,
      post: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
      }
    }

    $locationProvider.html5Mode(true)

    // disable IE ajax request caching
    //    $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache'
    //    $httpProvider.defaults.headers.get['Pragma'] = 'no-cache'

    // Set some Restangular (https://github.com/mgonto/restangular) configuration
    RestangularProvider.setBaseUrl(ENV.COMPLYOS_API_URL)
    RestangularProvider.setDefaultHeaders({ 'Content-Type': 'application/json' })
    RestangularProvider.setDefaultHttpFields({ withCredentials: true })

    // Config to handle array results being embedded in 'results'
    RestangularProvider.setResponseExtractor(function (response: any, operation: any) {
      if (operation === 'getList') {
        let newResponse
        if (response['results']) {
          newResponse = response.results
        } else {
          newResponse = response
        }

        return newResponse
      }
      return response
    })

    // https://github.com/mgonto/restangular/issues/78
    // Because we don't really want a object being sent from every DELETE request.
    // If you just make your request interceptor return undefined for your rails app, it'll work.
    RestangularProvider.setRequestInterceptor(function (elem: any, operation: any) {
      if (operation === 'remove') {
        return undefined
      }
      return elem
    })

    // Convert all the dates that are sent to the api
    RestangularProvider.addFullRequestInterceptor(function (element: any, operation: any, what: any, url: any, headers: any, params: any, httpConfig: any) {
      if ((operation === 'get') || (operation === 'getList')) {
        // copy as to not modify the original object
        params = angular.copy(params)

        // loop through and strip the gmt from any dates
        _.forIn(params, function (value: any, key: any) {
          if (!params.hasOwnProperty(key)) {
            // do nothing as the property comes from the constructor
          } else {
            if (params[key] instanceof Date) {
              const old_date = value
              return params[key] = `${old_date.getFullYear()}-${old_date.getMonth() + 1}-${old_date.getDate()}`
            }
          }
        })

        // return the modified params
        return { params }
      }
    })

    // SessionResolver inturrepts page load and ensures a valid session.
    // -- should be applied to any restricted route && public key removed
    // We need to validate a persons token BEFORE any route events begin,
    // that is done by calling sessionLauncherProvider in Resolve.
    // We use this to access tokens if they exist, then validate.
    function withSessionResolver (routeConfig: any) {
      // FYI: a controller is required when using resolve in routeConfig
      //        (update, not needed when routing directly to components, see "withComponentSessionResolver" and its uses)
      routeConfig.controller = routeConfig.controller || 'authentiwaitController'
      routeConfig.resolve = routeConfig.resolve || {}

      routeConfig.resolve.session = function () {
        return sessionLauncherProvider.withSession().then((success: any) => {
          // *sessionBreadCrumb* console.log(`app > withSessionResolver() :: VALID TOKEN :: ${success.response.token}`)
          return routeConfig
        }, (error: any) => {
          // *sessionBreadCrumb* console.log(`app > withSessionResolver() :: INVALID TOKEN :: ${error.response.failing_check}`)
          sessionLauncherProvider.clearSessionDataToken()
          return routeConfig
        });
      }

      return routeConfig
    }

    // Same as original session resolver: "withSessionResolver", but does not force a dummy controller
    //    This allows us to load directly into components (which is what all the cool kids are doing these days)
    function withComponentSessionResolver(routeConfig: any) {
      routeConfig.resolve = routeConfig.resolve || {}
      routeConfig.resolve.session = function () {
        return sessionLauncherProvider.withSession().then((success: any) => {
          // *sessionBreadCrumb* console.log(`app > withSessionResolver() :: VALID TOKEN :: ${success.response.token}`)
          return routeConfig
        }, (error: any) => {
          // *sessionBreadCrumb* console.log(`app > withSessionResolver() :: INVALID TOKEN :: ${error.response.failing_check}`)
          sessionLauncherProvider.clearSessionDataToken()
          return routeConfig
        });
      }

      return routeConfig
    }

    $stateProvider
      // main public facing pages
      .state('/main', ({
        url: '/main',
        component: 'ngLandingMain',
        permission: 'home_page_visit'
      }))
      .state('/marketing', ({
        url: '/marketing',
        component: 'ngMarketing',
        permission: 'home_page_visit'
      }))

      // Authentication
      .state('/user_services/login', {
        url: '/user_services/login',
        component: 'ngLogin',
        permission: 'home_page_visit'
      })
      .state('/user_services/sso', {
        url: '/user_services/sso',
        component: 'ngSsoLogin',
        permission: 'home_page_visit'
      })
      .state('/user_services/forgot_password', {
        url: '/user_services/forgot_password',
        component: 'ngForgotPassword',
        permission: 'home_page_visit'
      })
      .state('/user_services/:action/:service_token', {
        url: '/user_services/:action/:service_token',
        component: 'ngSetPassword',
        permission: 'home_page_visit'
      })

      // External Link App Initializer
      .state('/control_number/:control_number', withComponentSessionResolver({
        url: '/control_number/:control_number',
        component: 'ngControlNumbers',
        permission: 'home_page_visit'
      }))
      .state('/home', withComponentSessionResolver({
        url: '/home',
        component: 'ngHome',
        permission: 'home_page_visit'
      }))
      .state('/user/:user_id', withSessionResolver({
        url: '/user/:user_id',
        templateUrl: 'views/pages/user.html',
        permission: 'user_profile_page_visit'
      }))
      .state('/help', withComponentSessionResolver({
        url: '/help',
        component: 'ngHelp',
        permission: 'home_page_visit'
      }))
      .state('/complyos/references', withSessionResolver({
        url: '/complyos/references',
        templateUrl: 'views/pages/references.html',
        permission: 'references_page_visit'
      }))
      .state('/complyos/requirements', withComponentSessionResolver({
        url: '/complyos/requirements',
        component: 'ngRequirementsList',
        permission: 'requirements_page_visit'
      })) 
      // .state('/complyos/requirements', withSessionResolver({
      //   url: '/complyos/requirements',
      //   templateUrl: 'views/pages/requirements.html',
      //   permission: 'requirements_page_visit'
      // }))
      // .state('/complyos/organizations', withSessionResolver({
      //   url: '/complyos/organizations',
      //   templateUrl: 'views/pages/organizations.html',
      //   permission: 'organizations_page_visit'
      // }))
      
      .state('/complyos/users', withSessionResolver({
        url: '/complyos/users',
        templateUrl: 'views/pages/system_users.html',
        permission: 'users_complyos_page_visit'
      }))
      .state('/complyos/tags', withComponentSessionResolver({
        url: '/complyos/tags',
        component: 'ngTagsList',
        permission: 'tags_page_visit'
      }))
      .state('/complyos/dashboard', withComponentSessionResolver({
        url: '/complyos/dashboard',
        component: 'ngSystemDashboard',
        permission: 'dashboard_complyos_page_visit'
      }))
      .state('/complyos/reports', withComponentSessionResolver({
        url: '/complyos/reports',
        component: 'ngReports',
        permission: 'reports_page_visit'
      }))
      .state('/complyos/email_logs', withComponentSessionResolver({
        url: '/complyos/email_logs',
        component: 'ngEmailLogs',
        permission: 'email_logs_page_visit'
      }))
      .state('/complyos/communications', withSessionResolver({
        url: '/complyos/communications',
        templateUrl: 'views/pages/communications.html',
        permission: 'communications_page_visit'
      }))
      .state('/complyos/permissions', withComponentSessionResolver({
        url: '/complyos/permissions',
        component: 'ngPermission',
        permission: 'permissions_page_visit'
      }))
      .state('/complyos/operators', withSessionResolver({
        url: '/complyos/operators',
        templateUrl: 'views/pages/operators.html',
        permission: 'operators_page_visit'
      }))

      // .state('/organization/users', withSessionResolver({
      //   url: '/organization/users',
      //   templateUrl: 'views/pages/organization_users.html',
      //   permission: 'users_organization_page_visit'
      // }))
      .state('/organization/organization-list', withComponentSessionResolver({
        url: '/complyos/organizations',
        component: 'ngOrganizationList',
        permission: 'organizations_page_visit'
      }))
      .state('/organization/users', withComponentSessionResolver({
        url: '/organization/users',
        component: 'ngOrganizationUsers',
        permission: 'users_organization_page_visit'
      })) 
      .state('/organization/dashboard', withComponentSessionResolver({
        url: '/organization/dashboard',
        component: 'ngUserDashboard',
        permission: 'dashboard_organization_page_visit'
      }))
      .state('/organization/survey_mode', withComponentSessionResolver({
        url: '/organization/survey_mode',
        component: 'ngSurveyMode',
        permission: 'surveymode_page_visit'
      }))
      .state('/organization/review_findings', withSessionResolver({
        url: '/organization/review_findings',
        templateUrl: 'views/pages/review_findings.html',
        permission: 'home_page_visit'
      }))
      .state('/organization/validation_mode_comments', withComponentSessionResolver({
        url: '/organization/validation_mode_comments',
        component: 'ngValidationModeComments',
        permission: 'validationmode_page_visit'
      }))
      .state('/requirements/store', withComponentSessionResolver({
        url: '/requirements/store',
        component: 'ngStore',
        permission: 'store_page_visit'
      })) 
      .state('/requirements/binders', withComponentSessionResolver({
        url: '/requirements/binders',
        component: 'ngBindersList',
        permission: 'list_page_visit'
      }))
      .state('/requirements/binder/:binder_id', withComponentSessionResolver({
        url: '/requirements/binder/:binder_id',
        component: 'ngBinder',
        permission: 'binder_page_visit'
      })) 
      .state('/requirements/schedule', withComponentSessionResolver({
        url: '/requirements/schedule',
        component: 'ngScheduleList',
        permission: 'schedule_page_visit'
      })) 

      .state('/demo', withSessionResolver({
        url: '/demo',
        templateUrl: 'views/pages/demo.html',
        permission: 'home_page_visit'
      }))

      // Example route directly to controller & template (without the need for a basically blank page)
      .state('/reqstest', withSessionResolver({
        url: '/reqstest',
        controller: 'complyosRequirementsController',
        template: require('../views/panels/requirements/requirements.pug'),
        permission: 'requirements_page_visit'
      }))

      // Example: route directly to a component
      .state('/comptest', withComponentSessionResolver({
        url: '/comptest',
        component: 'ngRequirementsList',
        permission: 'requirements_page_visit'
      })) 

      $urlRouterProvider.otherwise('/main')
  }])

  // complyosClient.run(['$location', '$rootScope', '$timeout', '$uibModalStack', 'ENV', 'flashService', 'fortyCore', 'helpService', 'Restangular', 'sessionService', 'utils', function (
  .run(['$location', '$rootScope', '$timeout', '$transitions', '$uibModalStack', 'ENV', 'flashService', 'fortyCore', 'helpService', 'Restangular', 'sessionService', 'utils', 'authenticationService', function (
    $location: any,
    $rootScope: any,
    $timeout: any,
    $transitions: any,
    $uibModalStack: any,
    ENV: any,
    flashService: any,
    fortyCore: any,
    helpService: any,
    Restangular: any,
    sessionService: any,
    utils: any,
    authenticationService: any,
  ) {
    $rootScope.helpService = helpService
    $rootScope.session = sessionService
    $rootScope.session.initalize()

    // ### PERMISSION WALL ###
    // 'Catches' url changes before the app is fully loaded, 'Saves' for later use.
    $transitions.onStart({}, function (transition: any) {
      let target_state = transition.to()
      let route_requires_session = (
        target_state &&
        target_state.resolve &&
        target_state.resolve.session
      )
      let application_still_loading = (
        !$rootScope.session ||
        !$rootScope.session.data ||
        !$rootScope.session.data.happy
      )

      if (route_requires_session && application_still_loading) {
        $rootScope.session.saveColdCallSessionState(target_state)
      }
    })

    // User permission error redirect
    $transitions.onSuccess({}, function (transition: any) {
      // Prevents an error if user has not logged in yet
      if ($rootScope.session.getUser() != undefined) {
        let required_permission = transition.to().permission
        if (required_permission && !$rootScope.session.userCan(required_permission)) {
          $location.path('/home')
        }
      }
    })

    // a check to see if the user is using chrome.
    // if not, a message is displayed alerting the user on chrome providing best experience
    function check_for_chrome () {
      if (!fortyCore.detectionService.isChrome()) {
        return setTimeout(() =>
          $rootScope.$apply(() =>
            $rootScope.$broadcast(
              'add_global_alert', {
                name: 'browser_compatibility_warning',
                dismissable: true,
                class: 'alert-info',
                strong: 'Note',
                message: 'For the best user experience, please use Google Chrome.'
              }
            )
          )
        , 1000)
      }
    }
    check_for_chrome()

    // all the opened modals to be closed whenever a route is changed
    $transitions.onSuccess({}, () =>
      $uibModalStack.dismissAll()
    );

    Restangular.setErrorInterceptor(function (response: any, deferred: any, responseHandler: any) {
      // If a user has a old token (timeout/status code 498)
      if (response.status === 498) {
        // *sessionBreadCrumb* console.log('app > Restangular.setErrorInterceptor() :: 498')
        // user timesout with the window open -- suspend the state.
        if ($rootScope.session.data.happy) {
          $uibModalStack.dismissAll()
          $rootScope.session.suspendSession()
          $rootScope.$broadcast(
            'add_global_alert',
            flashService.default_alert_by_code(response.status)
          )

        // user has a old token & new browser window -- clear the token & wait.
        } else { // ! $rootScope.session.data.happy
          $rootScope.session.clearToken()
          $rootScope.session.validateOrWait()
        }

        return false
      }
      return true
    });

    // (function (i, s, o, g, r, a, m) {
    //   // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
    //   i['GoogleAnalyticsObject'] = r
    //   // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
    //   i[r] = i[r] || function () {
    //     // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
    //     (i[r].q = i[r].q || []).push(arguments)
    //   }

    //   // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
    //   i[r].l = 1 * new Date()

    //   // @ts-expect-error ts-migrate(2322) FIXME: Type 'HTMLElement' is not assignable to type 'unde... Remove this comment to see the full error message
    //   a = s.createElement(o)
    //   // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'undefine... Remove this comment to see the full error message
    //   m = s.getElementsByTagName(o)[0]

    //   // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    //   a.async = 1
    //   // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    //   a.src = g
    //   // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    //   m.parentNode.insertBefore(a, m)
    // })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga')
    // // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'ga'.
    // return ga('create', ENV.gaCode, 'auto')
  }])


// angular.element(document).ready(function () {
//   // angular.bootstrap(document.body, ['complyosClient', 'FortyAUServices'])
//   angular.bootstrap(document.body, ['complyosClient', 'FortyAUServices'], { strictDi: true })
// })
