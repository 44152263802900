import { Pipe, PipeTransform } from '@angular/core';
// this multi sort will have a fallback to sort by ID in case the first value is the same
@Pipe({
  name: 'multiplesort',
  pure: false
})
export class ArrayMultipleSortPipe implements PipeTransform {

  transform(array: any, field: string, type: string): any[] {
    if (!Array.isArray(array)) {
      return;
    }
    array.sort((a: any, b: any) => {
      if (a[field] < b[field]) {
        return -1;
      } else if (a[field] > b[field]) {
        return 1;
      } else if (a[field] === b[field]) {
        // sort by a secondary key (e.g., id) if first value is the same
        return a.id > b.id ? 1 : -1;
      } else {
        return 0;
      }
    });
    if(type === 'reverse') {
      return array.reverse();
    } else {
      return array;
    }
  }

}
