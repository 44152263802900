import { Component, OnInit, Inject } from '@angular/core';
import angular from 'angular';
import { ConfirmationModalFactory } from '../../../../scripts/services/ajs-confirmation_modal_factory';
import { Utils } from '../../../../scripts/services/ajs-utils';
import { FlashService } from '../../../services/flash_service.service';
import { helpService } from '../../../../scripts/services/help';
import _ from 'lodash';
import * as Restangular from '../../../../vendor/restangular/restangular'
import { downgradeComponent } from '@angular/upgrade/static';
import { treeManager } from '../../../../scripts/services/ajs-tree_manager_service';
import { TagsModalComponent } from '../../modals/tags-modal/tags-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'ngTagsList',
  templateUrl: './tags-list.component.html',
})

export class TagsListComponent implements OnInit {
  private flashService: any;
  private processingStateService: any;
  private loadingStateService: any;
  private session: any;

  // Properties
  allowIndependentToggle: boolean = true;
  tags: any;
  flatTags: any;
  tagQuery: string = ''


  constructor(
    @Inject('$uibModal') private $uibModal,
    @Inject('$scope') private $scope,
    @Inject('$rootScope') private $rootScope,
    @Inject(FlashService) flashService: FlashService,
    private helpService: helpService,
    @Inject('fortyCore') private fortyCore: any,
    @Inject(treeManager) private treeManager: any,
    @Inject('loadingStateService') loadingStateService: any,
    private Restangular: Restangular,
    private utils: Utils,
    private confirmationModalFactory: ConfirmationModalFactory,
    private dialog: MatDialog
  ) { 
    this.flashService = angular.copy(flashService)
    this.processingStateService = fortyCore.processingStateService
    this.loadingStateService = angular.copy(loadingStateService)
    this.helpService = angular.copy(helpService)
    this.fortyCore = angular.copy(fortyCore)
    this.treeManager = angular.copy(treeManager)
    this.session = this.$rootScope.session
  }

  ngOnInit() {
    this.loadingStateService.init()
    this.getTags()
  }

  updateTags(event: { tag: any, action: string }) {
    const { tag, action } = event;
    // update the tags list with the updated list from the child
    if(action === 'update') {
      tag.show = true
      this.treeManager.update_object_in_tree(this.tags, tag)
    } else if(action === 'destroy') {
      this.treeManager.remove_object_from_tree(this.tags, tag)
    }
  }

  getTags = () => {
    this.Restangular.all('tags').getList().then((success: any) => {
      this.loadingStateService.process_success(success)
      this.tags = success
      this.search()
      this.flatTags = this.treeManager.flatten_tree(success)
    }
    , (error: any) => {
      this.loadingStateService.process_error(error)
      return this.utils.log(error, 'error', true)
    })
  }

  openTagCreateModal = (tags: any) => {
    const new_tag_object = {}
    return this.openTagModal(new_tag_object, 'create', tags)
  }

  /* MODAL */

  openTagModal = (tag_object: any, ui_setting: any, tags: any) => {
    const dialogRef = this.dialog.open(TagsModalComponent, {
      width: '600px',
      data: { tag: angular.copy(tag_object), tags: angular.copy(tags), uiSetting: ui_setting }
    })

    dialogRef.beforeClosed().subscribe(result => {
      if (result === undefined) {
        // Handle clicking outside the modal to close
        this.setModalResting(tag_object)
        return dialogRef.close('outsideClick')
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      this.setModalResting(tag_object)
      if(result == 'close') {
        return
      }
      if (result !== undefined && result !== 'outsideClick') {
        this.treeManager.add_object_to_tree(this.tags, result)
        result.show = true
        this.setModalResting(tag_object)
        return this.flatTags.push(result)
      }
    })
  }

  setModalResting = (tag_object) => {
    this.processingStateService.set(
      tag_object,
      this.processingStateService.processing_states.RESTING
    )
  }

  /* FILTER */

  tagPassesFilter = (tag: any) => {
    const search_string = this.tagQuery.toLowerCase()
    let match = false

    if (search_string === '') {
      match = true
    }
    if (tag.title.toLowerCase().indexOf(search_string) >= 0) {
      match = true
    }

    return match
  }

  resetTagTreeVisibility = () => {
    this.treeManager.recursively_assign_key(this.tags, 'show', true)
    this.treeManager.recursively_assign_key(this.tags, 'showChildren', false)
  }

  search = _.debounce(() => {
    this.resetTagTreeVisibility();
    if (this.tagQuery !== '') {
      this.treeManager.recursively_assign_key(this.tags, 'show', false);

      const recursively_evaluate_visibility = (tag_array: any) => {
        let any_match = false
        // Loop over the tag_array and look for any_match in tree
        tag_array.forEach((tag: any) => {
          let exact_match = false
          let deep_match = false
          // check the top level tag for an exact_match
          if (this.tagQueryMatch(tag)) {
            exact_match = true
          }
          // loop over the children in a recursive manner look for a deep_match.
          if (tag.children) {
            deep_match = recursively_evaluate_visibility(tag.children)
          }
          // set any_match varible for recursion purposes.
          if (exact_match || deep_match) {
            any_match = true
          }
          // Assign show attributes based on match variables
          tag.show = (exact_match || deep_match)
          tag.showChildren = deep_match
        })
        return any_match
      }

      recursively_evaluate_visibility(this.tags)
    }
  }, 200)

  recordCount = () => {
    if (this.tagQuery) {
      let num = this.flatTags.filter((tag: any) => this.tagQueryMatch(tag)).length
      return num
    } else {
      let num = this.flatTags.length
      return num
    }
  }

  // function that actually does string comparison for matching.
  tagQueryMatch = (tag: any) => {
    const search_string = this.tagQuery.toLowerCase()
    let match = false

    if (search_string === '') {
      match = true
    } else if (tag.title.toLowerCase().indexOf(search_string) >= 0) {
      match = true
    }

    return match
  }
}

angular
  .module('complyosClient')
  .directive('ngTagsList', downgradeComponent({ component: TagsListComponent }) as angular.IDirectiveFactory)