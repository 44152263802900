import { Component, OnInit, Inject } from '@angular/core';
import angular from 'angular';
import { ConfirmationModalFactory } from '../../../scripts/services/ajs-confirmation_modal_factory';
import { Utils } from '../../../scripts/services/ajs-utils';
import { FlashService } from '../../services/flash_service.service';
import _ from 'lodash';
import * as Restangular from '../../../vendor/restangular/restangular'
import { downgradeComponent } from '@angular/upgrade/static';
import { helpService } from '../../../scripts/services/help';
import { ArrayFunctions } from '../../../scripts/utilities/ajs-array_functions';
import { interval } from 'rxjs';
import { FormBuilder, FormControl, NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
var dayjs = require("dayjs")

@Component({
  selector: 'ngSurveyMode',
  templateUrl: './survey-mode.component.html',
})

export class SurveyModeComponent implements OnInit {
  private flashService: any;
  private processingStateService: any;
  private loadingStateService: any;
  private session: any;

  // Properties
  binders = [];
  entityFilterOptions = [];
  searchFilter = '';
  quickSearchFilter = undefined;
  previousPills = null;
  outstandingPromises = [];
  binderLSS: any;
  quickSearchLSS: any;
  documentReviewState: any;
  entityFilter: any;
  dateParams: any;
  entityFilteredBinders = [];
  searchFilteredBinders = [];
  dateFilteredBinders = [];
  referenceMatch = false;
  activeOrganizationPresent: any;
  searchHelpers: any
  currentSearchHelper: any
  entityFilterForm: any;
  selectedBinder: any;
  failedSubmission: boolean = false;
  intervalId: any;
  entityFilterSubscription: Subscription;
  entityFilterControl: any;
  binderSort: string = 'none';

  constructor(
    @Inject('$q') private $q,
    @Inject('$uibModal') private $uibModal,
    @Inject('$scope') private $scope,
    @Inject('$rootScope') private $rootScope,
    @Inject(FlashService) flashService: FlashService,
    @Inject('fortyCore') private fortyCore: any,
    private helpService: helpService,
    @Inject('loadingStateService') loadingStateService: any,
    private Restangular: Restangular,
    private utils: Utils,
    private confirmationModalFactory: ConfirmationModalFactory,
    private arrayFunctions: ArrayFunctions,
    private formBuilder: FormBuilder,
  ) { 
    this.flashService = angular.copy(flashService)
    this.processingStateService = fortyCore.processingStateService;
    this.binderLSS = angular.copy(loadingStateService)
    this.session = this.$rootScope.session
    this.documentReviewState = this.$rootScope.session.data.documentReview

    const custom_states = {
      activeorgprompt: {
        name: 'activeorgprompt',
        icon: 'fa-building',
        text: 'Organization Required. Please select from filter above.'
      }
    };

    this.binderLSS.loading_states = _.merge(this.binderLSS.loading_states, custom_states)
    this.binderLSS.init()
    
    this.quickSearchLSS = angular.copy(loadingStateService)
    this.quickSearchLSS.init()

    this.searchHelpers = [
      'Interval (e.g. weekly, monthly, annual)',
      "Requirement (e.g. 'exit sign inspection')",
      'Reference number (e.g. 02.03.05, EP 3)'
    ]
    this.currentSearchHelper = this.searchHelpers[0]
    this.entityFilterControl = new FormControl();
  }

  ngOnInit() {
    this.entityFilterOptions = [
      {
        key: 'all',
        filterValue: '',
        displayLabel: 'All Surveying Entities'
      },
      {
        key: 'aaahc',
        filterValue: 'Accreditation Association for Ambulatory Health Care (AAAHC)',
        displayLabel: 'Accreditation Association for Ambulatory Health Care (AAAHC)'
      },
      {
        key: 'cap',
        filterValue: 'College of American Pathologists Accreditation Program (CAP)',
        displayLabel: 'College of American Pathologists Accreditation Program (CAP)'
      },
      {
        key: 'tjc-ahc',
        filterValue: 'The Joint Commission - Ambulatory Health Care (TJC AHC)',
        displayLabel: 'The Joint Commission - Ambulatory Health Care (TJC AHC)'
      },
      {
        key: 'tjc-hap',
        filterValue: 'The Joint Commission - Hospital Accreditation Program (TJC HAP)',
        displayLabel: 'The Joint Commission - Hospital Accreditation Program (TJC HAP)',
        quickSearchURL: 'reference_objects/aspects/tjc_full_list',
        quickSearchArray: []
      },
      {
        key: 'tjc-hap',
        filterValue: 'The Joint Commission - Lab Accreditation Program (TJC LAB)',
        displayLabel: 'The Joint Commission - Lab Accreditation Program (TJC LAB)'
      },
      {
        key: 'uca',
        filterValue: 'Urgent Care Association (UCA)',
        displayLabel: 'Urgent Care Association (UCA)'
      }
    ];
    this.watchDateFilter()
    this.watchDashboardFilter()
    // Manual searching
    this.watchSearchFilter()
    // used to ensure that when scope is destroyed the timer is destroyed as well
    this.$scope.$on('$destroy', () => this.stopTextCarousel())
    this.entityFilterSubscription = this.entityFilterControl.valueChanges.subscribe(newEntity => {
      if(newEntity) {
        this.getQSArrayOnFirstTouch(newEntity)
        this.entityFilter = newEntity
        this.documentReviewState.entityFilterKey = newEntity.key
      }
    })
  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      // Handle valid form submission
      console.log('Form Submitted!', form.value);
    } else {
      this.failedSubmission = true
    }
  }

  displayEntityFilter = (pills) => {
    if (!this.activeOrganizationPresent) {
      this.binderLSS.set_state('activeorgprompt')
    } else {
      this.getBinders(pills)
      this.emitResetDateRange()

      // $scope.documentReviewState = $rootScope.session.data.documentReview

      if (this.documentReviewState.entityFilterKey) {
        const selectedOption = this.entityFilterOptions.find(option => option.key === this.documentReviewState.entityFilterKey);
        this.entityFilterControl.setValue(selectedOption)
      } else {
        this.entityFilterControl.setValue(this.entityFilterOptions[0])
      }
    }
  }

  isOrgPillSelected = (pills: any) => {
    this.activeOrganizationPresent = pills.some((pill:any) => { return pill.Field === 'organizations_ids'})
    this.displayEntityFilter(pills)
  }

  abortAllPromises = (promiseArray: any) => {
    if (Array.isArray(promiseArray) && promiseArray.length > 0) {
      promiseArray.forEach(p => p.resolve('cancel_pending_queries'))
    }
    promiseArray.length = 0
  }

  watchDashboardFilter = () => {
    this.$scope.$on('filterChange-dashboard', (e: any, pills: any) => {
      let widgetShouldRefresh = this.arrayFunctions.hasMeaningfulListChange(this.previousPills, pills, ['sorted', 'pagination_params', 'acknowledged_scope']) || this.previousPills === null
      this.previousPills = pills
      if(widgetShouldRefresh) {
        this.abortAllPromises(this.outstandingPromises)
        setTimeout(() => this.isOrgPillSelected(pills), 50)
      }
    })
  }

  getBinders = (pills) => {
    let queryParams = {
      param_data: this.$rootScope.storeService.stringifyTagParams(
        this.$rootScope.storeService.pillsToObject(pills)
      ),
      date_range_begin: dayjs().subtract(10, 'years').$d,
      date_range_end: dayjs().$d
    }

    this.binderLSS.set_state('loading')

    let abort = this.$q.defer()
    this.outstandingPromises.push(abort)

    this.Restangular.all('binders/survey_mode').withHttpConfig({ timeout: abort.promise }).getList(queryParams).then((success: any) => {
      // prevent the no content loading message
      this.binderLSS.process_success({ status: 200 })
      this.binders = success
      this.entityFilteredBinders = success
      this.startTextCarousel()
      this.restoreQuickSearch()

      this.emitResetActivePreset()
      this.quickSearchFilter = undefined
      this.emitResetDateRange()
    }, (error: any) => {
      this.binderLSS.process_error(error)
    }).finally(function () {
      return true
    });
  }

  restoreQuickSearch = () => {
    // restore quicksearch filter selection if one was made
    if (
      this.documentReviewState.quickSearchHistory &&
      this.documentReviewState.quickSearchHistory.tjc &&
      this.quickSearchFilter
    ) {
      const searchArray = this.documentReviewState.quickSearchHistory.tjc
      this.applyQuickSearch(searchArray[searchArray.length - 1])
    }
  }

  updateBinderProcessingState = (binder: any) => {
    // binders dont have this property by default.
    // so if its the first time opening the binders modal, we need to give it a state.
    if (binder.processing_state === undefined) {
      binder.processing_state = this.processingStateService.processing_states.RESTING
    }

    const binderResting = binder.processing_state === this.processingStateService.processing_states.RESTING

    if (binderResting) {
      return this.processingStateService.set(binder, this.processingStateService.processing_states.PENDING)
    } else {
      return this.processingStateService.set(binder, this.processingStateService.processing_states.RESTING)
    }
  }

  // BATCH REVIEW METHODS

  openBatchReviewModal = (binder: any, uiSetting: any) => {
    this.selectedBinder = binder
    this.updateBinderProcessingState(binder)

    return this.Restangular.all(`binders/${binder.id}/entry_review`).getList(this.dateParams).then((success: any) => {
      binder.entries = success
      this.launchBatchReviewModal(binder.plain(), uiSetting)
    });
  }

  launchBatchReviewModal = (binderObject: any, uiSetting: any) => {
    const modalInstance = this.$uibModal.open({
      templateUrl: 'views/modals/notes/entry_validation_mode_comment.html',
      controller: 'entryValidationModeCommentModalController',
      size: 'xl',
      resolve: {
        modalOptions () {
          return {
            
            object: angular.copy(binderObject),
            uiSetting
          }
        }
      }
    })

    // for this modal instance, we arent passing back an updated object
    // so we skip both the success and failure block and head straight for the finally
    // for the sole purpose of updating the binder processing state
    return modalInstance.result.then(function (result: any) {
      console.log(result)
    }, function (reason: any) {
      console.log(reason)
    }).finally(() => {
      this.updateBinderProcessingState(this.findBinder(this.selectedBinder.id))
    });
  }

  /* QUICK SEARCH */

  quickSearchPanelVisible = () => {
    return (
      this.entityFilter &&
      this.entityFilter.quickSearchURL &&
      this.entityFilter.quickSearchArray && 
      this.activeOrganizationPresent
    )
  }

  applyQuickSearch = (quickSearchString: any) => {
    this.quickSearchFilter = quickSearchString
    // unset the searchFilter, there can be only one.
    this.searchFilter = undefined
    this.filterBinders()
    this.addToQSHistory(quickSearchString)
  }

  watchDateFilter = () => {
    return this.$rootScope.$on('date_filter_updated', (scope: any, object: any) => {
      this.dateParams = object
      this.filterBinders()
    });
  }

  emitResetDateRange = () => {
    this.$rootScope.$emit('reset_date_filter')
  }

  emitResetActivePreset = () => {
    this.$rootScope.$emit('reset_active_preset')
  }

  addToQSHistory = function (quickSearchString: any) {
    if (!this.inQSHistory(quickSearchString)) {
      this.emitResetDateRange()
      this.entityQSHistory().push(quickSearchString)
    }
  }

  entityQSHistory = () => {
    if (this.documentReviewState.quickSearchHistory === undefined) {
      this.documentReviewState.quickSearchHistory = {}
    }
    if (this.documentReviewState.quickSearchHistory[this.entityFilter.key] === undefined) {
      this.documentReviewState.quickSearchHistory[this.entityFilter.key] = []
    }

    return this.documentReviewState.quickSearchHistory[this.entityFilter.key]
  }

  inQSHistory = (quickSearchString: any) => {
    return this.entityQSHistory().find((historyItem: any) => {
      return historyItem === quickSearchString
    });
  }

  removeFromQSHistory = (quickSearchString: any) => {
    let historyIndex = this.entityQSHistory().findIndex((historyItem: any) => {
      return historyItem === quickSearchString
    })
    this.entityQSHistory().splice(historyIndex, 1)

    if (this.entityQSHistory().length === 0) {
      this.quickSearchFilter = undefined
    }
  }

  clearQSHistory = () => {
    this.documentReviewState.quickSearchHistory[this.entityFilter.key] = []
    this.quickSearchFilter = undefined
    this.emitResetActivePreset()
    this.emitResetDateRange()
  }

  // Get the quickSearchArray from the quickSearchUrl
  getQSArrayOnFirstTouch = (entity: any) => {
    if (entity.quickSearchURL && entity.quickSearchArray.length === 0) {
      let query_params = {
        filter: 'full'
      }
      this.quickSearchLSS.set_state('loading')

     this. Restangular.one(entity.quickSearchURL).get(query_params).then((success: any) => {
        entity.quickSearchArray = success.sort(this.sortAlphaNum)
        this.quickSearchLSS.set_state('content')
      }, (error: any) => {
        this.quickSearchLSS.process_error(error)
      })
    }
  }

  /* ENTITY FILTER */

  canLockEntityFilter = (entityFilter: any) => {
    if (entityFilter.key === 'all') {
      return this.failedSubmission = true
    } else {
      this.confirmLockEntityFilter()
    }
  }

  confirmLockEntityFilter = () => {
    // build object for user to confirm.
    const confirmation_object = {
      severity: 'warning',
      title: 'Confirm Lock',
      button_icon: 'fa-lock',
      button_text: 'Lock',
      message: `Are you sure you want to apply a survey lock for \
                '${this.entityFilter.displayLabel}'? <br> \
                Logging out will be required to undo this action`,
      // action to be called on confirm
      confirm: () => {
        // entity filter select visibility is based on session.isNavigationLocked()
        this.$rootScope.session.lockNavigation()
      }
    }
    // call the confirmation modal allow user to choose.
    this.confirmationModalFactory.openConfirmationModal(confirmation_object)
  }

  /* FILTER */

  watchSearchFilter = () => {
    this.$scope.$watch('searchFilter', (newValue: any, oldValue: any) => {
      if (newValue !== undefined) {
        this.emitResetActivePreset()
        // unset the quickSearchFilter, there can be only one filter
        this.quickSearchFilter = undefined
        // we want to reset the date range filter to 1 year each time
        // a new search is entered and call filterBinders from there
        this.emitResetDateRange()
      }
    }, true)
  }

  applyTextSearchFilter = () => {
    // if user types in the search bar, reset document search so all binders will be included
    this.quickSearchFilter = undefined
    this.filterBinders()
  }

  // the ng-change method that occurs when the search bar changes
  // kicks off the filtering off the filtering process
  filterBinders = () => {
    this.entityFilteredBinders = []
    this.dateFilteredBinders = []
    this.searchFilteredBinders = []

    // stage 1
    // Start with binders, no filter whatsoever.
    if (this.entityFilter) {
      // Apply the entityFilter, and save to pre_filterd_binders.
      _.each(this.binders, (binder: any) => {
        if (this.binderTextFilter(binder, this.entityFilter.filterValue)) {
          return this.entityFilteredBinders.push(binder)
        }
      })
    } else {
      this.entityFilteredBinders = this.binders
    }

    // stage 2
    // apply the date filter
    _.each(this.entityFilteredBinders, (binder: any) => {
      if (this.binderDateFilter(binder, this.dateParams)) {
        return this.dateFilteredBinders.push(binder)
      }
    })

    // stage 3
    // Start with result of stage 2, all the entityFilter action applied.
    // Apply the searchFilter, and save to searchFilteredBinders.
    return _.each(this.dateFilteredBinders, (binder: any) => {
      // only one search type can be used at a time, the other is set to undefined
      if (this.quickSearchFilter) {
        if (this.referenceFilter(binder, this.quickSearchFilter)) {
          return this.searchFilteredBinders.push(binder)
        }
      } else if (this.searchFilter) {
        if (this.binderTextFilter(binder, this.searchFilter)) {
          return this.searchFilteredBinders.push(binder)
        }
      }
    });
  }

  // called during the filter on change method.
  // allows for the reference match to be reset, in case the
  // user isnt searching by references anymore.
  resetReferenceMatch = () => this.referenceMatch = false

  // referenceFilter used by the quickSearch sidebar
  referenceFilter = (binder: any, query: any) => {
    let match = false
    const search_string = query.toLowerCase()
    _.each(binder.requirement.reference_objects, function (reference_object: any) {
      if (reference_object.value.toLowerCase() === search_string) {
        return match = true
      }
    })
    return match
  }

  // binder live filter
  binderTextFilter = (binder: any, query: any) => {
    let match = false
    const searchString = query.toLowerCase()

    if (searchString === '') {
      match = true
    }
    if ((binder.display_name.toLowerCase()).indexOf(searchString) >= 0) {
      match = true
    }
    if (binder.requirement.interval && (binder.requirement.interval.name.toLowerCase()).indexOf(searchString) >= 0) {
      match = true
    }
    _.each(binder.requirement.reference_objects, function (reference_object: any) {
      if ((reference_object.value.toLowerCase()).indexOf(searchString) >= 0) {
        return match = true
      }
    })
    _.each(binder.requirement.tags, function (tag: any) {
      if ((tag.title.toLowerCase()).indexOf(searchString) >= 0) {
        return match = true
      }
    })

    return match
  }

  binderDateFilter = (binder: any, dateParams: any) => {
    let match = false
    if (
      dateParams.date_range_begin <= binder.last_entry_date &&
      dateParams.date_range_end >= binder.first_entry_date
    ) {
      match = true
    } else {
      match = false
    }
    return match
  }

  // searches the references object and checks to see if the user is searching by a reference value
  // if they are we set referenceMatch to true and only show the references that match the
  // search criteria. Else, we hide the refernces column.
  relevantReferences = (referenceObjects: any) => {
    const activeFilter = this.searchFilter || this.quickSearchFilter || ''
    const lowerCaseActiveFilter = activeFilter ? activeFilter.toLowerCase() : ''
    const filteredObjects = referenceObjects.filter(referenceObject => {
      return referenceObject.value.toLowerCase().includes(lowerCaseActiveFilter) && lowerCaseActiveFilter !== '';
    })
    // update referenceMatch based on whether any reference object has a match
    this.referenceMatch = filteredObjects.length > 0

    return filteredObjects;
  }

  /* SORT */
  orderBindersByDisplayName = () => {
    if (this.binderSort == 'none' || this.binderSort == 'reverse') {
      this.binderSort = 'default'
      this.searchFilteredBinders.sort((a, b) => b.display_name.localeCompare(a.display_name))
    } else if (this.binderSort == 'default') {
      this.binderSort = 'reverse'
      this.searchFilteredBinders.sort((a, b) => a.display_name.localeCompare(b.display_name))
    }
  }

  // document names are alphanumeric strings
  // default sorting doesn't work because string values are compares so the resuts return as
  // [A1, A11, A12, ...]
  // This performs an alphanumeric sort so we get
  // [A9, A10, A11]
  sortAlphaNum = (a: any, b: any) => {
    a = String(a.value)
    b = String(b.value)
    let value = a.localeCompare(b, 'en', { numeric: true })
    return value
  }

  /* MISC */

  findBinder = (binder_id: any) => {
    return _.find(this.searchFilteredBinders, (b: any) => b.id === binder_id);
  }

  // used to create a carousel that rotates the search helper messages
  // stopper is a promise that allows the carousel to essentially 'stop' when a
  // search or filter occurs without this the timer goes haywire. to prevent the
  // haywire, the stopper stops the current timer and when get binders is called
  // again a new timer is established
  startTextCarousel = () => {
    this.intervalId = setInterval(() =>
      $('.fade-toggle').fadeOut(500, () =>
        setTimeout(() => {
          let currentSearchHelperIndex = this.searchHelpers.indexOf(this.currentSearchHelper)
          currentSearchHelperIndex++
          if (currentSearchHelperIndex === 3) {
            this.currentSearchHelper = this.searchHelpers[0]
          } else {
            this.currentSearchHelper = this.searchHelpers[currentSearchHelperIndex]
          }

          return $('.fade-toggle').fadeIn(500)
        }, 0)
      ), 7000)
  }

  // the next 2 methods are helpers to stop the carousel.
  // the stop functionality is also called when a user creates a new date filter and is restarted after loading
  stopTextCarousel = () => clearInterval(this.intervalId)
}

angular
  .module('complyosClient')
  .directive('ngSurveyMode', downgradeComponent({ component: SurveyModeComponent }) as angular.IDirectiveFactory)