import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import * as Restangular from '../../../../vendor/restangular/restangular'
import angular from 'angular';

@Component({
  selector: 'ngHomeLinks',
  templateUrl: './home-links.component.html'
})
export class HomeLinksComponent implements OnInit {
  // Properties
  private links: any;
  private session: any;
  private digestCollapsed: boolean = true
  private feedCollapsed: boolean = true

  constructor(
    @Inject('$rootScope') private $rootScope,
    private Restangular: Restangular
  ) {
    this.session = this.$rootScope.session
  }

  ngOnInit(): void {
    this.links = [
      {
        icon: 'fa-tachometer',
        title: 'Dashboard',
        text: 'Compliance at a glance',
        click: () => {
          return this.session.goTo('/organization/dashboard')
        },
        visible: () => {
          // LOGIC TAKEN FROM NAVIGATION.JS
          // navLinkOrganizationDashboard > visible()
          // Keep that current, then apply here.
          return (
            // this.session.activeOrganizationPresent() &&
            this.session.activeUserPresent() &&
            this.session.getUser().user_role.role.weight >= 30 &&
            this.session.getUser().user_role.role.title !== 'Requirement Author'
          )
        }
      },
      {
        icon: 'fa-calendar',
        title: 'Schedule',
        text: "What's coming due or is overdue",
        click: () => {
          return this.session.goTo('/requirements/schedule')
        },
        visible: () => {
          // LOGIC TAKEN FROM NAVIGATION.JS
          // navLinkRequirementsSchedule > visible()
          // Keep that current, then apply here.
          return (
            this.session.activeUserPresent() &&
            // this.session.activeOrganizationPresent() &&
            // this.session.activeOrganizationInteractable() &&
            this.session.userCan('binders_read') &&
            this.session.userCan('organizations_read')
          )
        }
      },
      {
        icon: 'fa-list',
        title: 'List',
        text: 'See all of your configured requirements here',
        click: () => {
          return this.session.goTo('/requirements/binders')
        },
        visible: () => {
          // LOGIC TAKEN FROM NAVIGATION.JS
          // navLinkRequirementsSchedule > visible()
          // Keep that current, then apply here.
          return (
            this.session.activeUserPresent() &&
            // this.session.activeOrganizationPresent() &&
            this.session.userCan('binders_read') &&
            this.session.userCan('organizations_read')
          )
        }
      },
      {
        icon: 'fa-newspaper-o',
        title: 'Notifications',
        text: 'All of your daily notifications in one place',
        click: () => {
          return this.collapseNotifications()
        },
        visible: () => {
          return (
            this.session.activeUserPresent() && (
              (this.session.getUser().user_role.role.title === 'Organization Admin') ||
              (this.session.getUser().user_role.role.title === 'User')
            )
          )
        }
      },
      {
        icon: 'fa-bullhorn',
        title: 'Communication Feed',
        text: 'Important announcements about the Complyos platform',
        click: () => {
          return this.collapsefeed()
        },
        visible: () => {
          return (
            this.session.activeUserPresent()
          )
        }
      },
      {
        icon: 'fa-shopping-cart',
        title: 'Store',
        text: 'View, acquire and configure new requirements',
        click: () => {
          return this.session.goTo('/requirements/store')
        },
        visible: () => {
          // LOGIC TAKEN FROM NAVIGATION.JS
          // navLinkRequirementsStore > visible()
          // Keep that current, then apply here.
          return (
            this.session.activeUserPresent() &&
            // this.session.activeOrganizationPresent() &&
            // this.session.activeOrganizationInteractable() &&
            this.session.userCan('binders_read') &&
            this.session.userCan('requirements_read') &&
            this.session.userCan('organizations_read')
          )
        }
      },
      {
        icon: 'fa-question-circle-o',
        title: 'Help',
        text: 'Find FAQ, Guides, Videos and More',
        click: () => {
          return this.session.goTo('help')
        },
        visible: () => {
          return true
        }
      }
    ]
    this.toggleFeedCollapsed()
  }

  collapseNotifications = () => {
    this.digestCollapsed = !this.digestCollapsed
    this.feedCollapsed = true
  }

  collapsefeed = () => {
    this.feedCollapsed = !this.feedCollapsed
    this.digestCollapsed = true
  }

  toggleFeedCollapsed = () => {
    this.Restangular.one(`users/${this.$rootScope.session.getUser().id}/feed`).get().then((response: any) => {
      if (response.new_count > 0) {
        return this.feedCollapsed = false
      }
    }
    , (error: any) => console.log(error))
  }
}

angular
  .module('complyosClient')
  .directive('ngHomeLinks', downgradeComponent({ component: HomeLinksComponent }) as angular.IDirectiveFactory)
