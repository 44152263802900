import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import angular from 'angular';
import { ConfirmationModalFactory } from '../../../../scripts/services/ajs-confirmation_modal_factory';
import { Utils } from '../../../../scripts/services/ajs-utils';
import { FlashService } from '../../../services/flash_service.service';
import { helpService } from '../../../../scripts/services/help';
import _ from 'lodash';
import * as Restangular from '../../../../vendor/restangular/restangular'
import { downgradeComponent } from '@angular/upgrade/static';
import { treeManager } from '../../../../scripts/services/ajs-tree_manager_service';
import { MatDialog } from '@angular/material/dialog';
import { TagsModalComponent } from '../../modals/tags-modal/tags-modal.component';

@Component({
  selector: 'ngTagsListRow',
  templateUrl: './tags-list-row.component.html',
})

export class TagsListRowComponent implements OnInit {
  private flashService: any;
  private loadingStateService: any;
  private session: any;
  private processingStateService: any;
  
  @Output() tagsUpdated = new EventEmitter<{ tag: any, action: string }>();
  @Input() tags: any;
  @Input() tag: any;
  // Properties
  allowIndependentToggle: boolean = true;
  flatTags: any;
  tagQuery: string = ''


  constructor(
    @Inject('$uibModal') private $uibModal,
    @Inject('$scope') private $scope,
    @Inject('$rootScope') private $rootScope,
    @Inject(FlashService) flashService: FlashService,
    private helpService: helpService,
    @Inject('fortyCore') private fortyCore: any,
    @Inject(treeManager) private treeManager: any,
    @Inject('loadingStateService') loadingStateService: any,
    private Restangular: Restangular,
    private utils: Utils,
    private confirmationModalFactory: ConfirmationModalFactory,
    private dialog: MatDialog
  ) { 
    this.flashService = angular.copy(flashService)
    this.loadingStateService = angular.copy(loadingStateService)
    this.helpService = angular.copy(helpService)
    this.fortyCore = angular.copy(fortyCore)
    this.treeManager = angular.copy(treeManager)
    this.processingStateService = fortyCore.processingStateService;
    this.session = this.$rootScope.session
  }

  ngOnInit() {
  }

  toggleShowChildren = (tag: any) => {
    if (tag.showChildren) {
      tag.showChildren = false
    } else {
      tag.showChildren = true
      this.treeManager.recursively_assign_key(tag.children, 'show', true)
    }
  }

  openTagReadModal = (tag_object: any, tag_list: any) => {
    // State service will set the object to a state of PENDING
    // After action is performed or cancelled,
    // that action will be responsible for setting object to RESTING state
    this.processingStateService.performFunction(tag_object, () => this.openTagModal(tag_object, 'read', tag_list))
  }

  openTagUpdateModal = (tag_object: any, tags: any) =>
    // State service will set the object to a state of PENDING
    // After action is performed or cancelled,
    // that action will be responsible for setting object to RESTING state
    this.processingStateService.performFunction(tag_object, () => this.openTagModal(tag_object, 'update', tags))

  openTagDestroyModal = (tag_object: any) => {
    const confirmation_object = {
      // severity: severity string
      // title: title string
      // button_icon: icon string
      // button_text: text string
      // deny: method to run on dismiss
      confirm: () => { return this.destroyTag(tag_object) },
      message:
        '<p>This action will:</p>' +
        '<ul>' +
          `<li>Delete the tag ${tag_object.title}</li>` +
          `<li>Delete all descendant tags of tag ${tag_object.title}</li>` +
          `<li>Delete all taggings for tag ${tag_object.title}</li>` +
          `<li>Delete all taggings of all descendants of tag ${tag_object.title}</li>` +
        '</ul>' +
        '<p>This action is not reversible.</p>'
    }

    // State service will set the object to a state of PENDING
    // After action is performed or cancelled,
    // that action will be responsible for setting object to RESTING state
    return this.processingStateService.performFunction(tag_object, () =>
      this.confirmationModalFactory.openConfirmationModal(confirmation_object).then((result: any) => {
        // Closing confirmation modal always gives a result
        // If result is false (cancel), then set back to resting
        if (!result) {
          this.processingStateService.set(
            tag_object,
            this.processingStateService.processing_states.RESTING
          )
        }
        return true
      })
    );
  }

  destroyTag = (tag_object: any) => { // State service will set the object to a state of PENDING
    // After action is performed or cancelled,
    // that action will be responsible for setting object to RESTING state
    this.Restangular.one('tags', tag_object.id).remove().then((success: any) => {
      this.tagsUpdated.emit({ tag: tag_object, action: 'destroy' })
    }
    , (error: any) => {
      this.processingStateService.set(
        tag_object,
        this.processingStateService.processing_states.RESTING
      )
      this.flashService.process_error(error)
      return this.utils.log(error, 'error', true)
    })
  }

  /* MODAL */

  openTagModal = (tag_object: any, ui_setting: any, tags: any) => {
    const dialogRef = this.dialog.open(TagsModalComponent, {
      width: '600px',
      data: { tag: angular.copy(tag_object), tags: angular.copy(tags), uiSetting: ui_setting }
    })

    dialogRef.beforeClosed().subscribe(result => {
      if (result === undefined) {
        // Handle clicking outside the modal to close
        this.setModalResting(tag_object)
        return dialogRef.close('outsideClick')
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      this.setModalResting(tag_object)
      if(result == 'close') {
        return
      }
      if (result !== undefined && result !== 'outsideClick') {
        this.tagsUpdated.emit({ tag: result, action: 'update' })
      }
    })
  }

  // this will set the ui to resting and get rid of the yellow warning row highlight
  setModalResting = (tag_object) => {
    this.processingStateService.set(
      tag_object,
      this.processingStateService.processing_states.RESTING
    )
  }

  // handle emitted events from the recursive child
  handleTagsUpdated(event: { tag: any, action: string }) {
    // emit the event further up the chain
    this.tagsUpdated.emit(event);
  }
}

angular
  .module('complyosClient')
  .directive('ngTagsListRow', downgradeComponent({ component: TagsListRowComponent }) as angular.IDirectiveFactory)