import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { downgradeComponent } from '@angular/upgrade/static';
import { helpService } from '../../../../scripts/services/help';
import { FlashService } from '../../../services/flash_service.service';
import * as Restangular from '../../../../vendor/restangular/restangular'
import { Utils } from '../../../../scripts/services/ajs-utils';
import angular from 'angular';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'ngTagsModal',
  templateUrl: './tags-modal.component.html'
})
export class TagsModalComponent implements OnInit {
  // Injected Services
  private flashService: any;
  private loadingStateService: any;
  private processingStateService: any;
  private session: any;

  // Properties
  uiSetting: string;
  tag: any;
  tags: any
  parentSelectOptions: any;
  formReady: boolean = false;
  tagForm: any = {}
  isLoading: boolean = true;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TagsModalComponent>,
    @Inject('$rootScope') private $rootScope,
    @Inject(FlashService) flashService: FlashService,
    @Inject('fortyCore') private fortyCore: any,
    private helpService: helpService,
    private Restangular: Restangular,
    private utils: Utils,
    private formBuilder: FormBuilder,
  ) {
    this.flashService = angular.copy(flashService)
    this.helpService = angular.copy(helpService)
    this.processingStateService = fortyCore.processingStateService
    this.session = $rootScope.session
  }

  ngOnInit(): void {
    this.uiSetting = this.data.uiSetting
    this.tag = this.data.tag
    this.parentSelectOptions = angular.copy(this.flatten_tags(this.data.tags))
    this.formInit()

    // setTimeout(() => {
    //   if (this.tag.children !== undefined) {
    //       this.disable_tags(this.tag)
    //   }
    // }, 1000)
  }

  formInit = () => {
    this.tagForm = this.formBuilder.group({
      title: new FormControl(this.tag.title, [Validators.required]),
      parent_id: new FormControl(this.tag.parent_id, this.isTaggingsAvailable() ? Validators.required : null),
    })
    this.formReady = true
    this.isLoading = false
  }

  isTaggingsAvailable() {
    return this.session.getUser().taggings.length > 0
  }

  /* DISPLAY */

  // creates a flattened array of the nested $scope.parent_select_opitons array and updates
  // the title with spacing for tree structure
  flatten_tags = (tags_array: any) => {
    tags_array.sort(function (a: any, b: any) {
        if (a['title'].toLowerCase() > b['title'].toLowerCase()) {
            return 1
        } else {
            return -1
        }
    })
    let flattened_tags = []
    let i = 0
    while (i < tags_array.length) {
        tags_array[i].title = `${this.determine_depth(tags_array[i])}${tags_array[i].title}`
        flattened_tags.push(tags_array[i])
        flattened_tags = flattened_tags.concat(this.flatten_tags(tags_array[i]['children']))
        i++
    }
    return flattened_tags
  }

  // creates dynamic indentions for the tag select menu
  // TODO maybe not the best name.
  determine_depth = (tag: any) => {
    let spacing = ''
    let i = 0
    while (i < tag.depth) {
        spacing += '\u00A0\u00A0'
        i++
    }
    return spacing
  }

  // helper method to disable select options that are self, children, descendants
  // Adds disabled attr option item in $('#parent_select option').
  // takes Arg:
  //   - tag: object
  // Returns
  //  nothing
  disable_tags = (tag: any) => {
    // Find self, disble
    let option
    for (option of Array.from($('#parent_select option'))) {
        if (option.value === `number:${tag.id}`) {
            option.disabled = true
        }
    }

    // find children, disable
    let i = 0
    while (i < tag.children.length) {
      for (option of Array.from($('#parent_select option'))) {
        if (option.value === `number:${tag.children[i].id}`) {
            option.disabled = true
          if (tag.children !== undefined) {
              this.disable_tags(tag.children[i])
          }
        }
      }
      i++
    }
  }

  submit_form = (tagForm: any) => {
    this.isLoading = true
    // State service will set the form_object to a state of PENDING
    // After action is performed or cancelled, that action will be responsible
    // for setting object to RESTING state
    if (this.tagForm.valid) {
      if (this.uiSetting === 'update') {
          return this.updateTag(this.tagForm.value)
      } else {
          return this.createTag(this.tagForm.value)
      }
    } else {
      this.tagForm.failedSubmission = true
      this.isLoading = false
      return this.tagForm.get('title').setErrors({invalid: true})
    }
  }

  createTag = (tag_object: any) => this.Restangular.all('tags').post(tag_object).then((success: any) => {
    this.isLoading = false
    this.closeModalAndReturn(success)
  }, (error: any) => {
    this.flashService.process_error(error)
    return this.utils.log(error, 'error', true)
    }).finally(() =>
      this.isLoading = false,
      this.processingStateService.set(
      this.tagForm,
      this.processingStateService.processing_states.RESTING
    )
  )

  updateTag = (tag_object: any) => this.Restangular.one('tags', this.tag.id).patch(tag_object).then((success: any) => {
    this.isLoading = false
    this.closeModalAndReturn(success)
  }, (error: any) => {
    this.flashService.process_error(error)
    return this.utils.log(error, 'error', true)
  }).finally(() =>
      this.isLoading = false,
      this.processingStateService.set(
      this.tagForm,
      this.processingStateService.processing_states.RESTING
    )
  )

  closeModal = () => this.dialogRef.close('close')

  closeModalAndReturn = (object: any) => this.dialogRef.close(object)
}

angular
  .module('complyosClient')
  .directive('ngTagsModal', downgradeComponent({ component: TagsModalComponent }) as angular.IDirectiveFactory)
